import React from 'react'

export const TwitterShareButton = ({ title, url }) => {
  return (
    <a
      class="twitter-share-button"
      href={`https://twitter.com/intent/tweet?text=${title}&url=${url}`}
    >
      Tweet
    </a>
  )
}
