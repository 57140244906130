import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import menuIcon from '../../content/assets/icons/menu.svg'
import githubIcon from '../../content/assets/icons/github.svg'
import { useWindowDimensions } from '../hooks/useWindowDimensions'
import { screenMd } from '../constants'

export const Header = ({ path }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { width } = useWindowDimensions()
  const showCondensedMenu = width < screenMd
  const toggleMenu = () => setMenuOpen(isOpen => !isOpen)

  const activeClass = page => {
    const isActive =
      (page === 'blog' && (/^\/$/.test(path) || /^\/blog\//.test(path))) ||
      (page === 'blog' && /^\/tags/.test(path)) ||
      (page === 'talks' && /^\/talks/.test(path)) ||
      (page === 'contact' && /^\/contact/.test(path))

    return isActive ? '--active' : ''
  }

  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(relativePath: { eq: "profile-pic-ryan.jpg" }) {
        childImageSharp {
          fixed(width: 60, height: 60, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const links = (
    <>
      <Link to="/" className={`header-link ${activeClass('blog')}`}>
        Blog
      </Link>
      <Link to="/talks" className={`header-link ${activeClass('talks')}`}>
        Talks
      </Link>
      <Link to="/contact" className={`header-link ${activeClass('contact')}`}>
        Contact
      </Link>
      <a
        href="https://github.com/bolandrm"
        className="header-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {showCondensedMenu ? (
          'Github'
        ) : (
          <img src={githubIcon} className="github-icon" alt="github" />
        )}
      </a>
    </>
  )

  return (
    <div className="header-container">
      <div className="header">
        <Link to="/" className="header-profile-pic-link">
          <Image fixed={data.avatar.childImageSharp.fixed} className="header-profile-pic" />
        </Link>
        <Link to="/" className="header-title-link">
          <h1>Ryan Boland</h1>
        </Link>

        {showCondensedMenu ? (
          <a href="#" className="header-link" onClick={toggleMenu}>
            <img src={menuIcon} className="menu-icon" alt="menu" />
          </a>
        ) : (
          links
        )}
      </div>
      {showCondensedMenu && menuOpen && <div className="header-condensed-menu">{links}</div>}
    </div>
  )
}
