import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import kebabCase from 'lodash/kebabCase'
import { TwitterShareButton } from './TwitterShareButton'

export const PostCallout = ({
  title,
  featuredImage,
  slug,
  date,
  description,
  tags,
  header = false,
  postUrl = null,
}) => {
  return (
    <div className="post-callout">
      {featuredImage && (
        <Link to={`/${slug}`} className="post-callout-image-link">
          <Image fluid={featuredImage.childImageSharp.fluid} />
        </Link>
      )}
      <div className="post-callout-content">
        <Link to={`/${slug}`} className={`post-callout-title ${header ? '--header' : ''}`}>
          {title}
        </Link>
        <div className="post-callout-date-line">
          <span className="post-callout-date">{date}</span>
          {tags.map(tag => (
            <Link to={`/tags/${kebabCase(tag)}`} className="post-callout-tag" key={tag}>
              {tag}
            </Link>
          ))}
        </div>
        {!header && (
          <div className="post-callout-description">
            {description}
            &nbsp;&nbsp;
            <Link className="standard-link" to={`/${slug}`}>
              Read
            </Link>
          </div>
        )}
        {header && <TwitterShareButton title={title} url={postUrl} />}
      </div>
    </div>
  )
}
